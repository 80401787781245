import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Image from 'material-ui-image';
import { useParams, useHistory } from 'react-router-dom';

import empty from '../../../../assets/no-image.jpg';
import { DataField } from '../../../../components/dataField';
import { TRANSLATIONS, URLS } from '../../../../staticData.json';
import { GlobalContext } from '../../../../context/GlobalState';
import { WarningDialog } from '../../../../components/warningDialog';
import { deleteCharacter, fetchCharacters } from '../../../../utilities/fetchy';
import { setCharactersSuccess } from '../../../../context/actions';

const { HOME_URL } = URLS;

const {
  NAME,
  RACE,
  PROFESSION,
  PERSONAL_QUALITIES,
  FEARS,
  WISHES,
  ABOUT,
  EDIT,
  DELETE,
} = TRANSLATIONS;

export const ViewCharacter = ({ editCharacter }) => {
  const history = useHistory();
  const { characterId } = useParams();
  const {
    state: {
      characters: { characters },
    },
    dispatch,
  } = useContext(GlobalContext);
  const [currentCharacter, setCurrentCharacter] = useState({});
  const {
    image,
    name,
    race,
    profession,
    personalQualities,
    fears,
    wishes,
    about,
  } = currentCharacter;
  const [isDeleteCharacterModalOpen, setIsDeleteCharacterModalOpen] =
    useState(false);

  useEffect(() => {
    if (characters) {
      const character = characters.find(({ slug }) => slug === characterId);
      character && setCurrentCharacter(character);
      !character && history.push(HOME_URL);
    }
  }, [characters, characterId, history]);

  const handleDeleteCharacter = async () => {
    await deleteCharacter(currentCharacter._id);
    const { success, res } = await fetchCharacters();
    if (success) return dispatch(setCharactersSuccess(res));
    history.push('/');
  };

  const openDeleteCharacterWarningModal = () =>
    setIsDeleteCharacterModalOpen(true);

  const closeDeleteCharacterWarningModal = () =>
    setIsDeleteCharacterModalOpen(false);

  return (
    <>
      <Grid container spacing={2} onSubmit={() => console.log('HELLO')}>
        <Grid item xs={12} md={3}>
          <Image src={image || empty} cover />
        </Grid>
        <Grid container item xs={12} md={9} alignItems="center">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DataField label={NAME} text={name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataField label={RACE} text={race} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataField label={PROFESSION} text={profession} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataField label={PERSONAL_QUALITIES} text={personalQualities} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataField label={FEARS} text={fears} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DataField label={WISHES} text={wishes} />
            </Grid>
            <Grid item xs={12}>
              <DataField label={ABOUT} text={about} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mt={3} textAlign="end">
            <Box mr={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={editCharacter}
              >
                {EDIT}
              </Button>
            </Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={openDeleteCharacterWarningModal}
            >
              {DELETE}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <WarningDialog
        isOpen={isDeleteCharacterModalOpen}
        handleConfirm={handleDeleteCharacter}
        handleClose={closeDeleteCharacterWarningModal}
      />
    </>
  );
};
