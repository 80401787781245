import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useParams } from 'react-router-dom';

import { PageContainer } from '../../components/pageContainer';
import { CreateCharacter } from './components/createCharacter';
import { EditCharacter } from './components/editCharacter';
import { ViewCharacter } from './components/viewCharacter';
import { URLS, TRANSLATIONS } from '../../staticData.json';
import fakeData from '../../fakeData.json';

const { HOME_URL } = URLS;

const { GO_BACK } = TRANSLATIONS;

// mode will be one of "view, create"
export const Character = ({ mode }) => {
  const { characterId } = useParams();
  const [isEdit, setIsEdit] = useState(false);

  const editCharacter = () => setIsEdit(true);
  const cancelEditCharacter = () => setIsEdit(false);

  const renderSideBar = () => (
    <Box textAlign="end">
      <Button
        component={Link}
        to={HOME_URL}
        color="primary"
        startIcon={<ArrowBackIcon />}
      >
        {GO_BACK}
      </Button>
    </Box>
  );

  return (
    <PageContainer sideBar={renderSideBar}>
      {!characterId && <CreateCharacter />}
      {characterId && !isEdit && (
        <ViewCharacter
          currentCharacter={fakeData[0]}
          editCharacter={editCharacter}
        />
      )}
      {characterId && isEdit && (
        <EditCharacter
          currentCharacter={fakeData[0]}
          cancelEditCharacter={cancelEditCharacter}
        />
      )}
    </PageContainer>
  );
};
