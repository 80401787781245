import {
  GET_CHARACTERS_SUCCESS,
  GET_CHARACTERS_PENDING,
  ADD_CHARACTER,
} from './types';

export const setCharactersSuccess = (payload) => ({
  type: GET_CHARACTERS_SUCCESS,
  payload,
});

export const setCharactersPending = () => ({
  type: GET_CHARACTERS_PENDING,
});

export const addCharacter = (payload) => ({
  type: ADD_CHARACTER,
  payload,
});
