import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Image from 'material-ui-image';
import { useParams, useHistory } from 'react-router-dom';

import empty from '../../../../assets/no-image.jpg';
import { UploadButton } from '../../../../components/uploadButton';
import { GlobalContext } from '../../../../context/GlobalState';
import {
  setCharactersSuccess,
  setCharactersPending,
} from '../../../../context/actions';
import { TRANSLATIONS, URLS } from '../../../../staticData.json';
import { updateCharacter, fetchCharacters } from '../../../../utilities/fetchy';

const { HOME_URL } = URLS;

const {
  NAME,
  RACE,
  PROFESSION,
  PERSONAL_QUALITIES,
  FEARS,
  WISHES,
  ABOUT,
  SAVE,
  CANCEL,
} = TRANSLATIONS;

export const EditCharacter = ({ cancelEditCharacter }) => {
  const history = useHistory();
  const { characterId } = useParams();
  const {
    state: {
      characters: { characters },
    },
    dispatch,
  } = useContext(GlobalContext);
  const [character, setCharacter] = useState({});
  const [imagePreview, setImagePreview] = useState('');
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [race, setRace] = useState('');
  const [profession, setProfession] = useState('');
  const [personalQualities, setPersonalQualities] = useState('');
  const [fears, setFears] = useState('');
  const [wishes, setWishes] = useState('');
  const [about, setAbout] = useState('');

  useEffect(() => {
    if (characters) {
      const character = characters.find(({ slug }) => slug === characterId);
      character && setCharacter(character);
      character && setName(character.name);
      character && setImagePreview(character.image);
      character && setImage(character.image);
      character && setRace(character.race);
      character && setProfession(character.profession);
      character && setPersonalQualities(character.personalQualities);
      character && setFears(character.fears);
      character && setWishes(character.wishes);
      character && setAbout(character.about);
      !character && history.push(HOME_URL);
    }
  }, [characters, history, characterId]);

  const getCharacterData = () => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', name);
    formData.append('race', race);
    formData.append('profession', profession);
    formData.append('personalQualities', personalQualities);
    formData.append('fears', fears);
    formData.append('wishes', wishes);
    formData.append('about', about);
    return formData;
  };

  const getCharacters = async () => {
    const { success, res } = await fetchCharacters();
    dispatch(setCharactersPending());
    if (success) return dispatch(setCharactersSuccess(res));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    file && setImage(file);
    file && setImagePreview(URL.createObjectURL(file));
  };

  const handleUpdateCharacter = async () => {
    const { success } = await updateCharacter(
      getCharacterData(),
      character._id
    );
    if (success) {
      getCharacters();
      cancelEditCharacter();
    }
  };

  const onSubmitSaveEdit = (e) => {
    e.preventDefault();
    handleUpdateCharacter();
  };

  return (
    <Grid component="form" container spacing={2} onSubmit={onSubmitSaveEdit}>
      <Grid item xs={12} md={3}>
        <Image src={imagePreview || empty} cover />
        <UploadButton onChange={handleImageUpload} />
      </Grid>
      <Grid container item xs={12} md={9} alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={NAME}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={RACE}
              fullWidth
              value={race}
              onChange={(e) => setRace(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={PROFESSION}
              fullWidth
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={PERSONAL_QUALITIES}
              fullWidth
              value={personalQualities}
              onChange={(e) => setPersonalQualities(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={FEARS}
              fullWidth
              value={fears}
              onChange={(e) => setFears(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={WISHES}
              fullWidth
              value={wishes}
              onChange={(e) => setWishes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-static"
              label={ABOUT}
              multiline
              rows={10}
              fullWidth
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3} textAlign="end">
          <Box display="inline-block" mr={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={cancelEditCharacter}
            >
              {CANCEL}
            </Button>
          </Box>
          <Button type="submit" color="primary" variant="contained">
            {SAVE}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
