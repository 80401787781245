import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { TRANSLATIONS } from '../../staticData.json';
const { YES, NO, WARNING_DIALOG_TITLE, WARNING_DIALOG_CONTENT } = TRANSLATIONS;

export const WarningDialog = ({ isOpen, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{WARNING_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {WARNING_DIALOG_CONTENT}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {NO}
        </Button>
        <Button onClick={handleConfirm} color="secondary" autoFocus>
          {YES}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
