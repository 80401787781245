/* eslint-disable import/no-anonymous-default-export */
import {
  GET_CHARACTERS_SUCCESS,
  GET_CHARACTERS_PENDING,
  ADD_CHARACTER,
} from './types';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_CHARACTERS_SUCCESS:
      return {
        ...state,
        characters: { characters: payload, status: 'success' },
      };
    case GET_CHARACTERS_PENDING:
      return {
        ...state,
        characters: { characters: null, status: 'pending' },
      };
    case ADD_CHARACTER:
      return {
        ...state,
        characters: {
          characters: [...state.characters.characters, payload],
          status: 'success',
        },
      };
    default:
      return state;
  }
};
