import React, { useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Home } from './pages/home';
import { Character } from './pages/character';
import { URLS } from './staticData.json';
import { fetchCharacters } from './utilities/fetchy';
import { setCharactersSuccess, setCharactersPending } from './context/actions';
import { GlobalContext } from './context/GlobalState';

const { NEW_CHARACTER_URL, VIEW_CHARACTER_URL, HOME_URL } = URLS;

const App = () => {
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const getCharacters = async () => {
      dispatch(setCharactersPending());
      const { success, res } = await fetchCharacters();
      if (success) return dispatch(setCharactersSuccess(res));
    };
    getCharacters();
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={NEW_CHARACTER_URL}>
        <Character mode="create" />
      </Route>
      <Route exact path={VIEW_CHARACTER_URL}>
        <Character mode="view" />
      </Route>
      <Route exact path={HOME_URL}>
        <Home />
      </Route>
      <Redirect to={HOME_URL} />
    </Switch>
  );
};

export default App;
