import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

export const PageContainer = ({ children, sideBar }) => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography component="h1" variant="h5">
            C-Keeper
          </Typography>
        </Toolbar>
      </AppBar>
      <Box my={5}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={2}>
              {sideBar && sideBar()}
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
