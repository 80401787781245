import React, { createContext, useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  characters: { characters: null, status: '' },
};

const GlobalContext = createContext(initialState);

function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
}
export { GlobalContext, GlobalProvider };
