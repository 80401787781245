import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { TRANSLATIONS } from '../../staticData.json';

const { UPLOAD_IMAGE } = TRANSLATIONS;

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'end',
    marginTop: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export const UploadButton = ({ onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={onChange}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          {UPLOAD_IMAGE}
        </Button>
      </label>
    </div>
  );
};
