import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import empty from '../../assets/no-image.jpg';

const useStyles = makeStyles({
  media: {
    height: 0,
    paddingTop: '100%',
  },
  title: {
    fontWeight: 700,
  },
});

export const CharacterCard = ({ name, profession, image, onClick }) => {
  const { media, title } = useStyles();
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardMedia className={media} image={image || empty} />
        <CardContent>
          <Typography className={title} variant="subtitle1" component="h2">
            {name}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {profession}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
