import React, { useState, useContext, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Link, useHistory } from 'react-router-dom';

import { PageContainer } from '../../components/pageContainer';
import { CharacterCard } from '../../components/characterCard';
import { GlobalContext } from '../../context/GlobalState';
import { TRANSLATIONS, URLS } from '../../staticData.json';
import { REQUEST_STATUS } from '../../constants';

const {
  FILTER,
  CHARACTER_SEARCH,
  EMPTY_CHARACTERS,
  CHARACTER_LIST_TITLE,
  AMOUNT_TO_SHOW,
  NEW_CHARACTER,
  CLEAR,
} = TRANSLATIONS;
const { SUCCESS, PENDING } = REQUEST_STATUS;

const { NEW_CHARACTER_URL } = URLS;

export const Home = () => {
  const history = useHistory();
  const {
    state: {
      characters: { characters, status },
    },
  } = useContext(GlobalContext);
  const [characterSearchString, setCharacterSearchString] = useState('');
  const [filteredCharacters, setFilteredCharacters] = useState(null);
  const [charactersPerPage, setCharactersPerPage] = useState(12);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const filterCharacters = () =>
      setFilteredCharacters(
        characters
          .filter(({ name }) =>
            name
              .toLowerCase()
              .includes(characterSearchString.toLocaleLowerCase())
          )
          .filter((_, index) => index < charactersPerPage)
      );
    characters && filterCharacters();
  }, [characters, charactersPerPage, characterSearchString]);

  useEffect(() => {
    const calculatePaginationPages = () => {
      const pages = Math.ceil(characters.length / charactersPerPage);
      setTotalNumberOfPages(pages || 1);
    };
    characters && calculatePaginationPages();
  }, [charactersPerPage, characters]);

  useEffect(() => {
    const setCharactersBasedOnPagination = () => {
      const indexOfLastCharacter = currentPage * charactersPerPage;
      const indexOfFirstCharatcer = indexOfLastCharacter - charactersPerPage;
      setFilteredCharacters(
        characters.slice(indexOfFirstCharatcer, indexOfLastCharacter)
      );
    };
    characters && setCharactersBasedOnPagination();
  }, [currentPage, charactersPerPage, characters]);

  const clearFilter = () => {
    setCharacterSearchString('');
    setCharactersPerPage(12);
    setCurrentPage(1);
  };

  const renderFilter = () => (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">{FILTER}</Typography>
        <Button color="primary" startIcon={<ClearIcon />} onClick={clearFilter}>
          {CLEAR}
        </Button>
      </Box>
      <Divider />
      <Box py={1}>
        <TextField
          label={AMOUNT_TO_SHOW}
          fullWidth
          value={charactersPerPage}
          onChange={(e) => setCharactersPerPage(e.target.value)}
        />
      </Box>
    </>
  );

  const onClickNavigateToCharacter = (characterId) => () =>
    history.push(`/character/${characterId}`);

  return (
    <PageContainer sideBar={renderFilter}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">{CHARACTER_LIST_TITLE}</Typography>
        <Button
          component={Link}
          to={NEW_CHARACTER_URL}
          color="primary"
          startIcon={<AddIcon />}
        >
          {NEW_CHARACTER}
        </Button>
      </Box>
      <Divider />
      <Box mt={1}>
        <TextField
          label={CHARACTER_SEARCH}
          fullWidth
          value={characterSearchString}
          onChange={(e) => setCharacterSearchString(e.target.value)}
        ></TextField>
        <Box my={3} textAlign="center" height="100%">
          <Grid container spacing={3}>
            {status === SUCCESS &&
              filteredCharacters &&
              filteredCharacters.map(({ slug, image, name, profession }) => (
                <Grid key={slug} item xs={12} sm={6} md={3} lg={2}>
                  <CharacterCard
                    image={image}
                    name={name}
                    profession={profession}
                    onClick={onClickNavigateToCharacter(slug)}
                  />
                </Grid>
              ))}
            {status === SUCCESS && !filteredCharacters && (
              <Grid item xs={12}>
                <Typography>{EMPTY_CHARACTERS}</Typography>
              </Grid>
            )}
            {status === PENDING && (
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
          {filteredCharacters && (
            <Box
              component={Pagination}
              count={totalNumberOfPages}
              onChange={(e, number) => setCurrentPage(number)}
              display="flex"
              justifyContent="center"
              mt={2}
            />
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};
