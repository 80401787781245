export const fetchCharacters = async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/characters`);
  if (!res.ok) return { success: false, res: null };
  return { success: true, res: await res.json() };
};

export const postCharacter = async (data) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/characters/create`,
    {
      method: 'POST',
      body: data,
    }
  );
  if (!res.ok) return { success: false, res: null };
  return { success: true, res: await res.json() };
};

export const updateCharacter = async (data, id) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/characters/${id}`,
    {
      method: 'PUT',
      body: data,
    }
  );
  if (!res.ok) return { success: false, res: null };
  return { success: true, res: await res.json() };
};

export const deleteCharacter = async (id) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/characters/${id}`,
    {
      method: 'DELETE',
    }
  );
  if (!res.ok) return { success: false, res: null };
  return { success: true, res: null };
};
