import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  typography: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
});

export const DataField = ({ label, text }) => {
  const { typography } = useStyles();
  return (
    <>
      <Typography variant="h6">{label}</Typography>
      <Typography className={typography}>{text}</Typography>
    </>
  );
};
