import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Image from 'material-ui-image';
import { useHistory } from 'react-router-dom';

import empty from '../../../../assets/no-image.jpg';
import { UploadButton } from '../../../../components/uploadButton';
import { GlobalContext } from '../../../../context/GlobalState';
import { postCharacter } from '../../../../utilities/fetchy';
import { TRANSLATIONS, URLS } from '../../../../staticData.json';
import { addCharacter } from '../../../../context/actions';

const { HOME_URL } = URLS;

const {
  NAME,
  RACE,
  PROFESSION,
  PERSONAL_QUALITIES,
  FEARS,
  WISHES,
  ABOUT,
  SAVE,
  CANCEL,
} = TRANSLATIONS;

export const CreateCharacter = () => {
  const history = useHistory();
  const { dispatch } = useContext(GlobalContext);
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [race, setRace] = useState('');
  const [profession, setProfession] = useState('');
  const [personalQualities, setPersonalQualities] = useState('');
  const [fears, setFears] = useState('');
  const [wishes, setWishes] = useState('');
  const [about, setAbout] = useState('');

  const getCharacterData = () => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('name', name);
    formData.append('race', race);
    formData.append('profession', profession);
    formData.append('personalQualities', personalQualities);
    formData.append('fears', fears);
    formData.append('wishes', wishes);
    formData.append('about', about);
    return formData;
  };
  const navigateToHome = () => history.push(HOME_URL);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    file && setImage(file);
  };

  const handleCreateNewCharacter = async () => {
    const { success, res } = await postCharacter(getCharacterData());
    if (success) {
      dispatch(addCharacter(res));
      navigateToHome();
    }
  };

  const getImagePreviewSrc = () => image && URL.createObjectURL(image);

  const onSubmitCreateCharacter = (e) => {
    e.preventDefault();
    handleCreateNewCharacter();
  };

  return (
    <Grid
      component="form"
      container
      spacing={2}
      onSubmit={onSubmitCreateCharacter}
    >
      <Grid item xs={12} md={3}>
        <Image src={getImagePreviewSrc() || empty} cover />
        <UploadButton onChange={handleImageUpload} />
      </Grid>
      <Grid container item xs={12} md={9} alignItems="flex-start">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={NAME}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={RACE}
              fullWidth
              value={race}
              onChange={(e) => setRace(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={PROFESSION}
              fullWidth
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={PERSONAL_QUALITIES}
              fullWidth
              value={personalQualities}
              onChange={(e) => setPersonalQualities(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={FEARS}
              fullWidth
              value={fears}
              onChange={(e) => setFears(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={WISHES}
              fullWidth
              value={wishes}
              onChange={(e) => setWishes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-static"
              label={ABOUT}
              multiline
              rows={10}
              fullWidth
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3} textAlign="end">
          <Box display="inline-block" mr={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={navigateToHome}
            >
              {CANCEL}
            </Button>
          </Box>
          <Button type="submit" color="primary" variant="contained">
            {SAVE}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
